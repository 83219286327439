.animated-height {
  transition: height 1s ease;
  z-index: 1;
  overflow: hidden;
}

.animated-height:not([open]) {
  height: 38px;
}

.animated-height[open] {
  height: 100%;
  min-height: fit-content;
}

@media screen and (max-width: 768px) {
  .animated-height[open] {
    height: 60%;
  }
}