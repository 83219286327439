* {
    box-sizing: border-box;
}

.overflow-hidden-body {
    height: fit-content !important;
    overflow-x: hidden;
    background-color: rgb(249, 249, 249);
    /* margin: 0 8px 0 8px; */
}

#highcharts-ry2yvml-29,
#highcharts-ry2yvml-19,
#highcharts-ry2yvml-24 {
    border-radius: 15px;
}

.jaxriL {
    border-radius: 15px !important;
    padding: 10px;
}

.eeCEVb {
    margin-bottom: 25px !important;
}

.iJwSXj {
    margin-bottom: 1rem !important;
}

.bffFHq {
    border-radius: 15px;
}

table {
    border-spacing: 0;
    overflow-x: scroll;
}

table thead tr {
    width: 100%;
    position: sticky;
    z-index: 1;
    /* overflow-x: scroll; */
}

table th {
    color: #9ba1a6;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    border-bottom: 1px solid #dfe3e6;
}

table tr {
    cursor: pointer;
    height: 48px !important;
}

tr:first-child {
    top: 0;
    background: white;
}

table th:nth-of-type(1) {
    width: 59px;
}

.farmColumn {
    width: 150px;
}

.otherColumn {
    width: 85px;
}

.earnColumn {
    width: 100px;
}

table th:nth-of-type(2) {
    left: 0;
    background-color: white;
    z-index: 10;
    /* box-shadow: 0px 0px 2px rgba(128,138,157,.12),0px 0px 24px rgba(128,138,157,.14);     */
}

table th:nth-of-type(3) {
    width: 125px;
}

table th:nth-of-type(4) {
    width: 120px;
}

table th:nth-of-type(5) {
    width: 110px;
}

table td:nth-of-type(1) {
    color: #7A8291;
    font-weight: 400;
    font-size: 12px;
}

table td:nth-of-type(2) {
    left: 0;
    background-color: white;
    color: 'black';
    font-weight: 500;
    font-size: 12px;
}

table td:nth-of-type(3) {
    color: 'black';
    font-weight: 400;
    font-size: 12px;

}

table td:nth-of-type(4) {
    color: #7A8291;
    font-weight: 400;
    font-size: 12px;

}

table td:nth-of-type(5) {
    color: #17E7D6;
    font-weight: 600;
    font-size: 13px;
}

.nameParentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.nameParentDiv img {
    height: 24px;
    margin-right: 5px
}

.nameParentDiv>p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    margin-right: 10px;
    text-align: left;
}

.nameParentDiv>p>span {
    color: #7a8291;
    margin-left: 5px
}

.swapBtnParentDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swapBtn {
    width: 59px;
    height: 21px;
    border-radius: 19px;
    background-color: #112455;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 10px */
}

.swapBtn p {
    color: #17E7D6;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 10
}

.tableChild {
    width: 600px;
    margin-bottom: 25px;
}

/* g.highcharts-label{
    display: none;
} */

.highcharts-input-group {
    display: none;
}

.custom-selector {
    width: 98px;
    height: 22px;
    border-radius: 23px;
    border: 1px solid #17E7D6;
    /* padding-left: 5px; */
    box-sizing: border-box;
}

.custom-selector select {
    width: 98px;
    height: 22px;
    border-radius: 23px;
    font-size: 10px;
    text-align: center;
    font-weight: 700;
    background-color: transparent;
    border: 1px solid #17E7D6;
    color: #112455;
    font-family: Montserrat;
}

.custom-select {
    position: relative;
    display: inline-block;
    height: 22px;
    border-radius: 23px;
    width: 98px;
    /* Adjust the width as needed */
    font-family: Arial, sans-serif;
    top: -1px;
}

.selected-value {
    width: 98px;
    height: 22px;
    display: flex;
    border-radius: 23px;
    align-items: center;
    /* border: 1px solid #ccc; */
    cursor: pointer;
    user-select: none;
    justify-content: space-around;
    font-size: 10px;
    color: #112455;
    font-weight: 700;
    font-size: 8px;
}

.custom-options {
    display: none;
    position: absolute;
    width: 100%;
    max-height: 150px;
    /* Adjust the max height as needed */
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1;
}

.custom-option {
    padding: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #112455;
    overflow-x: hidden;
}

.custom-option:hover {
    background: #f5f5f5;
}

.open {
    display: block;
}



@media screen and (max-width:1500px) {
    .tableChild {
        width: 98%;
        margin-bottom: 23px;
    }
}

@media screen and (max-width:900px) {


    .glkkmo {
        width: 98% !important;
    }

}

@media screen and (max-width: 500px) {
    /* .custom-selector {
        width: 50px;
    } */

    .custom-selector select {
        width: 57px;
    }
}