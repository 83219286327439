:where(.css-dev-only-do-not-override-190m0jy).ant-modal .ant-modal-footer {
    text-align: end;
    background: transparent;
    margin-top: 12px;
    display: none;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #17E7D6;
    border-color: #17E7D6;
}